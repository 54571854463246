import { LoadingElement } from 'components';
import { useLocalization } from 'hooks';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch } from 'store/hooks';
import {
  RegistrationTypes,
  showAppAlert,
  startAppLoading,
  stopAppLoading,
  updateRegistrationType,
} from 'store/slices/appSlice';
import { useIntercom } from 'react-use-intercom';
import {
  useBaseLanguage,
  useSanityOrganization,
  useSanityOrganizationPrograms,
  useSettings,
} from '@guider-global/sanity-hooks';
import { ThemeContext } from 'context/theme';
import { createTheme } from '@mui/material';
import { datadogLogs } from '@guider-global/datadog';
let { version: REACT_APP_VERSION } = require('../../../package.json');

export interface SetupWrapperProps {
  organizationSlug: string;
  organizationGetSilently?: boolean;
  settingsGetSilently?: boolean;
  organizationProgramsGetSilently?: boolean;
}

export const SetupWrapper: FC<PropsWithChildren<SetupWrapperProps>> = ({
  organizationSlug,
  organizationGetSilently = true,
  organizationProgramsGetSilently = true,
  children,
}) => {
  // Context
  const { theme, setTheme } = useContext(ThemeContext);
  // Local State
  const [loading, setLoading] = useState<boolean>(true);

  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  // - Localization
  const { localeCode } = useLocalization(organizationSlug);
  // - Intercom
  const { boot: bootIntercom } = useIntercom();
  // - Sanity BaseLanguage

  const { isLoadingBaseLanguage, isErrorBaseLanguage, errorBaseLanguage } =
    useBaseLanguage({ localeCode });

  // - Sanity Organization
  const {
    isLoadingSanityOrganization,
    isErrorSanityOrganization,
    isSuccessSanityOrganization,
    getErrorsSanityOrganization,
    getOrganization,
  } = useSanityOrganization({
    organizationSlug,
    getSilently: organizationGetSilently,
    localeCode,
  });
  const loadingSanityOrganization = isLoadingSanityOrganization();
  const errorSanityOrganization = isErrorSanityOrganization();
  const successSanityOrganization = isSuccessSanityOrganization();
  const sanityOrganization = getOrganization();
  const sanityOrganizationAccess = sanityOrganization.access;
  const sanityOrganizationWhiteLabel = sanityOrganization.white_label;

  const isLocalAccountRegistration =
    sanityOrganizationAccess.local_account_registration;
  const sanityOrganizationConfigurations =
    sanityOrganizationAccess.configuration_array ?? [];
  const isSSOMultitenants = sanityOrganizationConfigurations.length > 0;

  // - Sanity Programs
  const {
    getErrorsSanityPrograms,
    isErrorSanityPrograms,
    isLoadingSanityPrograms,
    isSuccessSanityPrograms,
  } = useSanityOrganizationPrograms({
    organizationSlug,
    getSilently: organizationProgramsGetSilently,
    localeCode,
  });
  const loadingSanityOrganizationPrograms = isLoadingSanityPrograms();
  const errorSanityOrganizationPrograms = isErrorSanityPrograms();
  const successSanityOrganizationPrograms = isSuccessSanityPrograms();

  // - Sanity Settings
  const { settings, isErrorSettings, isLoadingSettings, errorSettings } =
    useSettings({ localeCode });
  // Event
  const setCustomTheme = useCallback(() => {
    if (!sanityOrganizationWhiteLabel) return;
    const organizationColorPalette =
      sanityOrganizationWhiteLabel.color_palette_config.color_palette ?? {};
    const organizationColors = Object.entries(organizationColorPalette).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          main: value.hex,
          transparent: `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, 0.1)`,
        },
      }),
      {},
    );
    const customTheme = createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        ...organizationColors,
      },
    });
    setTheme(customTheme);
    return customTheme;
  }, [setTheme, theme, sanityOrganizationWhiteLabel]);

  useEffect(() => {
    bootIntercom({
      customAttributes: {
        organizationSlug,
        appVersion: REACT_APP_VERSION,
      },
    });
  });

  useEffect(() => {
    if (
      (loadingSanityOrganization && !errorSanityOrganization) ||
      (isLoadingBaseLanguage && !errorBaseLanguage) ||
      (isLoadingSettings && !errorSettings) ||
      (loadingSanityOrganizationPrograms && !errorSanityOrganizationPrograms)
    ) {
      dispatch(startAppLoading(true));
      setLoading(true);
    }
    if (loading && successSanityOrganization) {
      setCustomTheme();
    }
    if (
      successSanityOrganization &&
      !errorBaseLanguage &&
      settings &&
      successSanityOrganizationPrograms
    ) {
      dispatch(stopAppLoading(true));
      setLoading(false);
    }
  }, [
    dispatch,
    errorSanityOrganization,
    errorSanityOrganizationPrograms,
    isErrorSettings,
    loading,
    loadingSanityOrganization,
    loadingSanityOrganizationPrograms,
    isLoadingSettings,
    setCustomTheme,
    settings,
    successSanityOrganization,
    successSanityOrganizationPrograms,
    isLoadingBaseLanguage,
    isErrorBaseLanguage,
    errorBaseLanguage,
    errorSettings,
  ]);

  useEffect(() => {
    const handleDispatchedError = (
      errorCode?: string,
      errorMessage?: string,
    ) => {
      const message = errorMessage ?? 'Undefined Error Message';
      console.error('error', { errorCode, errorMessage });
      datadogLogs.logger.error('setupWrapper: ', {
        errorCode,
        errorMessage,
      });
      dispatch(
        showAppAlert({
          severity: 'error',
          message: message,
          timeout: 10000,
        }),
      );
      dispatch(stopAppLoading(true));

      setLoading(false);
    };

    if (errorBaseLanguage) {
      const baseLanguageErrors = errorBaseLanguage;
      const errorCode = 'SanityBaseLanguage';
      const errorMessage = baseLanguageErrors.message;
      handleDispatchedError(errorCode, errorMessage);
    }
    if (errorSanityOrganization) {
      const organizationErrors = getErrorsSanityOrganization();
      const errorCode = 'SanityOrganization';
      const errorMessage = organizationErrors[0].message;
      handleDispatchedError(errorCode, errorMessage);
    }
    if (isErrorSettings) {
      const errorCodes = 'SanitySettings';
      const errorMessages = `${errorSettings?.message}`;
      handleDispatchedError(errorCodes, errorMessages);
    }
    if (errorSanityOrganizationPrograms) {
      const organizationProgramsErrors = getErrorsSanityPrograms();
      const errorCode = 'SanityOrganizationPrograms';
      const errorMessage = organizationProgramsErrors[0].message;
      handleDispatchedError(errorCode, errorMessage);
    }
  }, [
    dispatch,
    errorSanityOrganization,
    errorSanityOrganizationPrograms,
    errorSettings,
    isErrorSettings,
    getErrorsSanityOrganization,
    getErrorsSanityPrograms,
    errorBaseLanguage,
  ]);

  useEffect(() => {
    const handleUpdateRegistrationType = (key: RegistrationTypes) => {
      dispatch(updateRegistrationType(key));
    };

    if (loading) return;
    if (isLocalAccountRegistration && isSSOMultitenants) {
      handleUpdateRegistrationType('localAccountsAndSSO');
      return;
    }
    if (isLocalAccountRegistration) {
      handleUpdateRegistrationType('localAccounts');
      return;
    }
    if (isSSOMultitenants) {
      handleUpdateRegistrationType('multitenantsSSO');
      return;
    }
    handleUpdateRegistrationType('none');
  }, [dispatch, isLocalAccountRegistration, isSSOMultitenants, loading]);

  useEffect(() => {
    if (loading) return;

    const error = errorBaseLanguage;

    if (
      error?.message === 'Empty base languages' ||
      window.location.hostname === 'guider.app'
    ) {
      window.location.replace('https://guider-ai.com/');
    }
  }, [loading, errorBaseLanguage]);

  if (loading && !localeCode) return <LoadingElement />;

  return children;
};
